/**
 * Site header
 */
.site-header {
    min-height: 56px;

    // Positioning context for the mobile navigation icon
    position: relative;
}
.site-header a {
    text-decoration: none;
}

.site-header > .wrapper {
    border-bottom: 1px solid $grey-color-light;
}

.site-title {
    font-size: $big-font-size * 1.2;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: -1px;
    margin-bottom: 0;
    float: left;

    &,
    &:visited {
        color: $grey-color-dark;
    }
}

.site-nav {
    float: right;
    line-height: 56px;

    .menu-icon {
        display: none;
    }

    .page-link {
        color: $text-color;
        line-height: $base-line-height;
        font-size: $big-font-size;

        // Gaps between nav items, but not on the last one
        &:not(:last-child) {
            margin-right: 20px;
        }
    }

    @include media-query($on-palm) {
        position: absolute;
        top: 9px;
        right: $spacing-unit / 2;
        background-color: $background-color;
        border: 1px solid $grey-color-light;
        border-radius: 5px;
        text-align: right;

        .menu-icon {
            display: block;
            float: right;
            width: 36px;
            height: 26px;
            line-height: 0;
            padding-top: 10px;
            text-align: center;

            > svg {
                width: 18px;
                height: 15px;

                path {
                    fill: $grey-color-dark;
                }
            }
        }

        .trigger {
            clear: both;
            display: none;
        }

        &:hover .trigger {
            display: block;
            padding-bottom: 5px;
        }

        .page-link {
            display: block;
            padding: 5px 10px;

            &:not(:last-child) {
                margin-right: 0;
            }
            margin-left: 20px;
        }
    }
}


// Set min body / container size so footer is still on bottom of short pages
html { height: 100% }
body { min-height: 100% }
.container {
    position: absolute;
    min-height: 100%;
    min-width: 100%;
}
// Footer styling
.footer {
    position : absolute;
    bottom : 0;
    width: 100%;
    display: flex;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    background-color: $grey-color-light;
    justify-content: center;
}
.footer-icon-wrapper {
    margin: 0.5rem;
}
.footer-icon-wrapper a i {
    position: relative;
    top: 3px;  // Offset the icon so its vertically centered in footer
}


/**
 * Page content
 */
.page-content {
    padding: $spacing-unit 0;
    padding-bottom: 5rem;  // Extra padding to support footer
}

.page-heading {
    font-size: 20px;
}

.post-list {
    margin-left: 0;
    list-style: none;
}
.post-list > li {
    border-bottom: 1px solid $grey-color;
    margin-bottom: $spacing-unit * 1.5;
}

.post-meta {
    font-size: $big-font-size * 0.8;
    color: $grey-color;
}

.rss-subscribe {
    font-size: $small-font-size;
    color: $grey-color;
}



/**
 * Posts
 */
.post-header {
    margin-bottom: $spacing-unit * 0.5;
}

.post-title {
    font-size: $big-font-size * 1.6;
    font-weight: 600;
    letter-spacing: -1px;
    line-height: 1;

    @include media-query($on-laptop) {
        font-size: 36px;
    }
}
.post-title a {
    text-decoration: none;
    color: black;
}
.post-title a:hover {
    text-decoration: underline;
}

.post-content {
    margin-top: $spacing-unit;
    margin-bottom: $spacing-unit * 1.5;

    hr {
        color: $grey-color-light;
        margin: $spacing-unit 0;
    }

    h3 {
        font-size: 26px;
        color: $grey-color-dark;

        @include media-query($on-laptop) {
            font-size: 22px;
        }
    }

    h4 {
        font-size: 20px;
        font-weight: bold;
        color: $grey-color-dark;

        @include media-query($on-laptop) {
            font-size: 18px;
        }
    }
}
