@charset "utf-8";

// SASS Variables

$base-font-family: 'Lato', 'Helvetica', 'Segoe UI', Verdana, sans-serif;
$base-font-weight: 400;
$base-line-height: 1.5;
$base-font-size:   18px;
$small-font-size:  $base-font-size * 0.75;
$big-font-size:    $base-font-size * 1.4;

$spacing-unit:     30px;

$content-width:    800px;

$on-palm:          600px;
$on-laptop:        800px;

$text-color:       #474953;
$background-color: #fafafa;

$link:             #009ed9;
$link-visited:     #005b8c;

$grey-color:       #999;
$grey-color-light: lighten($grey-color, 33%);
$grey-color-dark:  darken($grey-color, 20%);



// Use media queries like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}



// Import partials from `sass_dir` (defaults to `_sass`)
@import
    "base",
    "layout",
    "syntax-highlighting",
    "svg-icons"
;

// Custom CSS / Flexbox
.container {
    max-width: 100%;
}
.welcome-box {
    margin-bottom: 1.5rem;
}
.project-box-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.project-box {
    @media (min-width: 36em) {
        max-width: 48%;
    }
    margin-bottom: 2rem;
}
.project-title {
    font-weight: bold;
}
.project-image {
    border: 1px solid $grey-color-dark;
    margin-bottom: 1rem;
}
.post-content img {
    display: block;
    max-width: 80%;
    margin: 1.5rem auto;
    border: 1px solid rgba(0,0,0,0.1);
    box-shadow: rgba(27, 31, 35, 0.15) 0px 2px 8px 0px;
}
